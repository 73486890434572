module.exports = {
  siteTitle: "Mr. Travel Tech", // <title>
  shortSiteTitle: "Mr. Travel Tech - (c) 2018", // <title> ending for posts and pages
  siteDescription: "Mr. Travel Tech brings brings stories of travel, tips and tricks.",
  siteUrl: "https://mrtraveltech.com",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",
  // author
  authorName: "Mr. Travel Tech",
  authorTwitterAccount: "mrtraveltech",
  // info
  headerTitle: "Mr. Travel Tech",
  headerSubTitle: "Travel stories, hacks and technology.",
  // manifest.json
  manifestName: "Mr. Travel Tech",
  manifestShortName: "MrTravelTech", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
//    { name: "github", url: "https://github.com/greglobinski" },
    { name: "twitter", url: "https://twitter.com/mrtraveltech" },
    { name: "facebook", url: "http://facebook.com/mrtraveltech" }
  ]
};
